@import 'fonts/riforma.css';
@import 'fonts/bradford.css';
@import 'modal.css';

:root {
  --color-primary: #1a3a32;
  --color-primary10: rgb(26 58 50 / 0.1);
  --color-primary12: rgb(26 58 50 / 0.12);
  --color-secondary: #9b9c9a;
  --color-successDark: #15604e;
  --color-success: #1c7d67;
  --color-successLight: #25a789;
  --color-danger: #f15e5e;
  --color-muted: #d2d6d1;
  --color-transparent: #ffffff40;
  --color-beige: #f3eeeb;
  --color-white: #fff;
  --color-light: #fffcf9;
  --color-whiteChalk: rgb(228 229 226);
  --color-light-06: rgb(255 252 249 / 0.6);
  --color-light-01: rgb(255 252 249 / 0.1);
  --color-dark: #030d0b;
  --color-gray: rgb(178 181 184);
  --color-lightSuccess: rgb(7 116 91 / 0.071);
  --color-lightPrimary: rgb(26 58 50 / 0.02);
  --color-lightPrimaryText: rgb(26 58 50 / 0.6);
  --color-turquoise: #defff8;
  --color-turquoise-70: rgb(222 255 248 / 0.7);
  --color-turquoise-90: rgb(222 255 248 / 0.9);
  --color-pink: rgb(248 190 190 / 1);
  --color-pink-95: rgb(248 190 190 / 0.95);
  --color-pink-90: rgb(248 190 190 / 0.9);
  --color-marroon: rgb(147 21 21 / 1);
  --color-dark-theme: #1b1c1d;
  --color-gray40: rgb(26 58 50 / 0.4);
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #fffcf9;
  color: var(--color-primary);
}

.main-content {
  padding-top: 73px;
  padding-bottom: 198px;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.main-content-page-width {
  max-width: 992px;
}

.footer-main-content {
  padding-top: 173px;
  padding-bottom: 198px;
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
}

.sticky-offset {
  top: 173px;
}

/* prevent scrolling body when modal is opened */
body.modal-open {
  overflow: hidden !important;
}

@media (width <= 991px) {
  .main-content {
    padding: 0.5rem 0 3rem;
  }

  .sticky-offset {
    top: 9rem;
  }
}

@media (width >= 992px) {
  .shadow-on-hover {
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .shadow-on-hover::after {
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .shadow-on-hover:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .shadow-on-hover:hover::after {
    opacity: 1;
  }
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.label {
  font-size: 12px;
  letter-spacing: 1px;
}

.swiper-pagination-bullet-active {
  background-color: #1a3a32 !important;
}

.swiper-slide {
  height: auto !important;
}

.swiper-button-disabled {
  visibility: hidden;
}

.nav-link {
  font-size: 14px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  opacity: 0.4;
}

.nav-link:hover {
  color: #1a3a32 !important;
}

.top-nav-hover {
  background-color: #2c483f !important;
  border-color: #2c483f !important;
}

.top-nav-hover:hover {
  background-color: rgb(26 58 50) !important;
  border-color: rgb(26 58 50) !important;
}

.vl-metrics-container {
  display: flex;
  flex-direction: column;
}

.grey-hovered-border {
  border: 1px solid rgb(26 58 50 / 0.2);
}

.grey-hovered-border:hover {
  border-color: rgb(26 58 50 / 0.5);
}

.turquoise-hovered-border {
  border: 1px solid var(--color-turquoise-70);
}

.turquoise-hovered-border:hover {
  border-color: var(--color-turquoise);
}

/* overflow-ellipsis */
.clamp-lines-1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.clamp-lines-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.clamp-lines-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.clamp-lines-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}

.letter-spacing-004 {
  letter-spacing: 0.04px;
}

.letter-spacing-005 {
  letter-spacing: 0.05px;
}

.letter-spacing-006 {
  letter-spacing: 0.06px;
}

.letter-spacing-067 {
  letter-spacing: 0.067px;
}

.letter-spacing-007 {
  letter-spacing: 0.07px;
}

.letter-spacing-01 {
  letter-spacing: 0.1px;
}

.letter-spacing-0667 {
  letter-spacing: 0.667px;
}

.letter-spacing-08 {
  letter-spacing: 0.8px;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-12 {
  letter-spacing: 1.2px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

/* border radius */
.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-17 {
  border-radius: 17px;
}

.border-radius-18 {
  border-radius: 18px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-26 {
  border-radius: 26px;
}

.border-radius-36 {
  border-radius: 36px;
}

.border-radius-46 {
  border-radius: 46px;
}

.border-radius-60 {
  border-radius: 60px;
}

.fs-13px {
  font-size: 13px;
}

/** Hide "x" in inputs */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.hide_scroll_bar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.hide_scroll_bar::-webkit-scrollbar {
  width: 0 !important;
}

.transparent {
  background-color: transparent;
}

.clip_to_bottom {
  background-color: #fffcf9;
  border-radius: 32px 32px 0 0;
  box-shadow: 0 -2px 24px rgb(0 0 0 / 0.1024);
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1020;
}

.gradient-bottom {
  position: absolute;
  bottom: 0;
  z-index: 1060;
  background: linear-gradient(to bottom, transparent, #fffcf9);
}

.btn-close:focus {
  box-shadow: none;
}

.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-centered-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-centered-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.white-space-break {
  white-space: break-spaces;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.rounded-50 {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

/* opacity */

.opacity-20 {
  opacity: 0.2;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.w-40 {
  width: 40% !important;
}

.w-inherit {
  width: inherit !important;
}

.w-fit-content {
  width: fit-content !important;
}

.pt-100 {
  padding-top: 100%;
}

.bg-black {
  background: black;
}

.bg-darkTheme {
  background: #1b1c1d !important;
}

.bg-darkTheme80 {
  background: #1b1c1d;
}

.backdrop-blur {
  backdrop-filter: blur(6.5px);
}

.required::after {
  content: ' *' !important;
  color: #f15e5e;
  vertical-align: top;
}

/* hide scrollbar on windows until focused */
.overflow-auto-hidden {
  overflow: auto;
}

@media (width >= 992px) {
  .overflow-auto-hidden {
    overflow: hidden;
  }

  .overflow-auto-hidden:hover {
    overflow: auto;
  }
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
